import React, { Fragment, useEffect, useState } from 'react';
import './styles.css';

import Asesoria from "../../../../img/asesoria.jpg"
// import Documentos from "../../../../img/documentos.png"
import Legal from "../../../../img/serlegales.jpg"
import Constructora from "../../../../img/Constructora.jpg"

const Index = () => {

  return (
    <div id="page-3" className='mainContServicios'>
      <h2>Servicios</h2>
      <div className='borderDecoradorServicios'></div>
      <p>Permítenos guiarte, te acompañamos de principio a fin</p>

      <div className='main-cont-servicios-kolel'>

        <div className='cont-servicio'>
          <div>
            <img src={Asesoria} />
          </div>
          <h4>Asesoramiento personalizado</h4>
          <p>
            Te asesoramos en inversiones inmobiliarios ya que contamos con la capacidad de entender tus necesidades y expectativas, para guiarte en la búsqueda de la propiedad ideal de vivienda y de inversión.
            Ofreciendote un soporte durante todo el proceso de compra o renta para ayudarte a tomar la mejor decisión con el objetivo de asegurar tu inversión.
          </p>
        </div>

        {/* <div className='cont-servicio'>
          <div>
            <img src={Documentos} />
          </div>
          <h4>Gestión de la documentación</h4>
          <p>
            Otorgamos asesoría legal en la gestión de la documentación en la compra o renta de una vivienda, facilitando la obtención de los documentos necesarios, como son los contratos, escrituras, certificaciones, y permisos para garantizar que estén en regla y vigentes.
          </p>
        </div> */}

        <div className='cont-servicio'>
          <div>
            <img src={Legal} />
          </div>
          <h4>Servicios legales</h4>
          <p>Contamos con un equipo de profesionales para ofrecerle los servicios de asesoría legal para evitar riesgos, y proteger los intereses de los clientes.. Entre estos servicios se incluyen la redacción y revisión de contratos, la asesoría en cuestiones fiscales y la resolución de conflictos entre propietarios e inquilinos.</p>
        </div>

      </div>

      <div className='sec-extras-servicios'>
        <div>
          <img src={Constructora} />
        </div>
        <h5>Servicio para Constructoras y Desarrolladores</h5>
        <p>
          Contamos con un equipo multidisciplinario para la elaboración de tus proyectos, tramitología y la administración, de acuerdo a tus intereses y necesidades, con el objetivo de garantizar tu inversión
        </p>
      </div>

    </div>
  )
}

export default Index