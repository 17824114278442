import React from 'react';
import './styles.css';

const Index = () => {

  return (
    <div className='mainContMapaLocacion'>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d453.1134005090986!2d-89.5790697057114!3d21.02524395598181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f56771609d7b52b%3A0x20134d775406896a!2sInmobiliaria%20Koolel-Kaab!5e0!3m2!1ses-419!2smx!4v1715618683553!5m2!1ses-419!2smx"
        allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
    </div>
  )
}

export default Index