import React, { Fragment, useEffect, useState } from 'react';
import './styles.css';

const Index = () => {

  return (
    <div id="page-1" className='mainContQuienesSomos'>
      <h1>¡SOMOS TU MEJOR opción!</h1>
      <p>Somos un grupo de profesionales que nos especializamos en Desarrollo de negocios, consultoría y estrategias financieras de inversión inmobiliaria contribuyendo a mejorar tu estilo de vida.
        Somos los indicados para asesorarte contamos con personal certificado.</p>
      <a href='#page-3' className='btn-conocenos-quienes-somos'>
        <label>Conoce nuestros servicios</label>
      </a>

    </div>
  )
}

export default Index